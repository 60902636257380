
import { HashRouter as Router, Route, Routes, Link, useParams, useLocation } from 'react-router-dom';

import './App.css';
import React, { useState, useRef, useEffect } from 'react';
import './output.css';
import StationPage from './StationPage'
import stations from './stations.json'; // Importing stations data from JSON file

const filteredStations = stations.filter(station => {
    return station.enabled === true;
});
//import { Button } from "@/components/ui/button"

function App() {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/station/:stationId" element={<StationPage />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

const Home = () => {
    const localeToLanguageMap = {
        en: 'English',
        es: 'Español',
        fr: 'Français',
        de: 'Deutsch',
        it: 'Italiano',
        sv: 'Svenska',
    };

    // Group stations by localeIdentifier
    const stationsByLocale = filteredStations.reduce((acc, station) => {
        const { localeIdentifier } = station;
        if (!acc[localeIdentifier]) {
            acc[localeIdentifier] = [];
        }
        acc[localeIdentifier].push(station);
        return acc;
    }, {});

    return (
        <div className="bg-white border-slate-100 dark:bg-slate-800 dark:border-slate-500 border-b p-4 pb-6 sm:p-10 sm:pb-8 lg:p-6 xl:p-10 xl:pb-8 space-y-6 sm:space-y-8 lg:space-y-6 xl:space-y-8 min-h-screen">
            {Object.entries(stationsByLocale).map(([locale, stations]) => (
                <div key={locale}>
                    <h2 className="text-black dark:text-white text-xl font-bold mb-4">{localeToLanguageMap[locale] || locale.toUpperCase()}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {stations.map((station) => (
                            <Link to={`/station/${station.id}`} state={{ station }} key={station.id}>
                                <StationRowCard station={station} />
                            </Link>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};


const Header = () => {
    return (
        <header className="bg-gray-700 text-white p-4">
            <div className="container mx-auto flex justify-between items-center">
                <Link to='/'><h1 className="text-xl font-bold">TextRadio</h1></Link>
                <nav>
                    <ul className="flex space-x-4">
                        <li className="font-bold"><Link to='/'>Home</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

const Footer = () => {
    return (
        <footer className="bg-gray-700 text-white text-center p-4">

        </footer>
    );
};

const StationRowCard = ({ station, onClick }) => {
    return (
        <div className="max-w-sm rounded overflow-hidden m-2 bg-gray-300 dark:bg-gray-500">
            <div className="flex flex-row items-center space-x-4 h-10">
                <img src={"/images/" + station.image} alt="" width="40" height="40" className="flex-none rounded-lg bg-slate-100" loading="lazy" />
                <div className="text-black dark:text-white text-sm leading-6 font-bold">
                    {station.title}
                </div>
                <img src={"/images/flags/" + station.localeIdentifier + ".svg"} alt="" width="20" height="20" className="center-vertical" loading="lazy" />
            </div>
        </div>
    );
};

const ProgressBar = () => {
    return (
        <div className="space-y-2">
            <div className="relative">
                <div className="bg-slate-100 dark:bg-slate-700 rounded-full overflow-hidden">
                    <div className="bg-cyan-500 dark:bg-cyan-400 w-1/2 h-2" role="progressbar" aria-label="music progress" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div className="flex justify-between text-sm leading-6 font-medium tabular-nums">
                <div className="text-cyan-500 dark:text-slate-10"></div>
                <div className="text-slate-500 dark:text-slate-400"></div>
            </div>
        </div>
    );
}

const ControlButtons = () => {
    return (
        <div className="bg-slate-50 text-slate-500 dark:bg-slate-600 dark:text-slate-200 rounded-b-xl flex items-center">
            {/* Button Group */}
            <div className="flex-auto flex items-center justify-evenly">
                {/* Add Your Buttons Here */}
            </div>
        </div>
    );
}

export default App;
