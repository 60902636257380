import React, {useEffect, useRef, useState} from 'react';
import App from "./App";
import {useParams} from "react-router-dom";
import stations from "./stations.json";
import Hls from 'hls.js';
import { Button, ToggleSwitch } from 'flowbite-react'

const StationPage = () => {
    const [currentStation, setCurrentStation] = useState(null);
    const { stationId } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        var station = stations.find(s => s.id == stationId);
        setCurrentStation(station);
    }, [stationId]);

    if (!currentStation) return <div>Station not found</div>;

    return (
            <div className="flex flex-col min-h-screen">
                <StationCard station={currentStation}/>
            </div>
    );
};

const isDev = () =>     !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const StationCard = ({ station }) => {
    //const [lastWord, setLastWord] = useState('');
    const lastWordRef = useRef('');
    const [tooltip, setTooltip] = useState({ show: false, content: '', position: { x: 0, y: 0 } });

    const [isTranslating, setIsTranslating] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);
    const [latestMessage, setLatestMessage] = useState('');
    const [translatedLatestMessage, setTranslatedLatestMessage] = useState('');

    const audioRef = useRef(null);
    const ws1Ref = useRef(null);
    const ws2Ref = useRef(null);

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const transcriptionLatency = isSafari ? station.transcriptionLatencies[0] : station.transcriptionLatencies[1];

// Define a generic function to connect to a WebSocket server
    const connectWebSocket = (urlSuffix, messageHandler) => {
        const scheme = "wss";
        const hostname = isDev() ? "t.125.io" : document.location.hostname;
        const url = `${scheme}://${hostname}/ws/${station.id}${urlSuffix}`;

        const ws = new WebSocket(url);

        ws.onopen = () => {
            console.log('Connected to the WebSocket server');
        };

        ws.onmessage = (event) => {
            if (event.data.trim().length > 0) {
                messageHandler(event.data);
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = () => {
            console.log('Disconnected from the WebSocket server');
            // Attempt to reconnect after 2 seconds
            setTimeout(() => connectWebSocket(urlSuffix, messageHandler), 2000);
        };

        return ws; // Return the WebSocket object in case it needs to be referenced elsewhere
    };

// Use the refactored function for both WebSocket connections
    const initializeWebSocketConnections = () => {
        ws1Ref.current = connectWebSocket("", (data) => {
            setTimeout(() => {
                        setLatestMessage(data);
                    }
                    , station.translationLatency*1000);
        });
        if (station.translation) {
            ws2Ref.current = connectWebSocket("_en", (data) => setTranslatedLatestMessage(data));
        }

    };

    const isHls = Hls.isSupported() && (station.streamURL.endsWith(".m3u8") || station.streamURL.endsWith(".m3u"));

    useEffect(() => {
        if (isHls) {
            const hls = new Hls();
            hls.loadSource(station.streamURL);
            hls.attachMedia(audioRef.current);
            // hls.on(Hls.Events.MANIFEST_PARSED, function () {
            //     if (isPlaying) {
            //         audioRef.current.play();
            //     }
            // });
        } else if (audioRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            // This will work in Safari, where HLS is supported natively
            audioRef.current.src = station.streamURL;
        } else {
            audioRef.current.src = station.streamURL;
        }

        initializeWebSocketConnections()
        if (station.localeIdentifier != "en") {
            document.addEventListener("mousemove", handleMouseMove);
        }

        // Clean up function to close WebSocket connection when the component unmounts
        return () => {
            if (ws1Ref.current) {
                ws1Ref.current.close();
            }
            if (ws2Ref.current) {
                ws2Ref.current.close();
            }
            if (station.localeIdentifier != "en") {
                document.removeEventListener("mousemove", handleMouseMove);
            }
        };
    }, [station.id]); // Re-run effect if station.id changes

    function playWithDelay() {
        let extraDelay = !isSafari && isHls ? 0.175 : 0;
        audioRef.current.play()
            .then(() => {
                    if (transcriptionLatency > 0) { //&& station.id != "dlf" ) {
                        audioRef.current.pause();
                        setTimeout(() => {
                                audioRef.current.play();
                            }
                            , transcriptionLatency * 1000);
                    }
                }
            );
    }

    const togglePlayPause = () => {
        var context = new AudioContext();

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            if (isSafari) {
                audioRef.current.src = station.streamURL;    // Directly setting the source
                playWithDelay();
            } else {
                let url = station.streamURL;
                if (Hls.isSupported() && (url.endsWith(".m3u8") || url.endsWith(".m3u"))) {
                    const hls = new Hls();
                    hls.loadSource(station.streamURL);
                    hls.attachMedia(audioRef.current);

                    playWithDelay();
                } else {
                    playWithDelay();
                }
            }
        }
        setIsPlaying(!isPlaying);
    };

    const reload = () => {
        window.location.reload();
    };

    const translate = async (word) => {
        // Mock translation function
        return `Translated ${word}`;
    };

    const handleMouseEnter = async (event, word) => {
        const translation = await translate(word);
        setTooltip({
            show: true,
            content: translation,
            x: event.clientX,
            y: event.clientY
        });
    };

    const handleMouseLeave = () => {
        setTooltip({ ...tooltip, show: false });
    };

    function getContext(clientPos) {
        var i, begin, end, range, textNode, offset;

        // Chrome
        if (document.caretRangeFromPoint) {
            range = document.caretRangeFromPoint(clientPos.left, clientPos.top);
            textNode = range.startContainer;
            offset = range.startOffset;
        }

        // Only act on text nodes
        if (!textNode || textNode.nodeType !== Node.TEXT_NODE) {
            return "";
        }

        var data = textNode.textContent;
        return data;
    }

// Get the full word the cursor is over regardless of span breaks
    const getFullWord = (clientPos) => {
        var i, begin, end, range, textNode, offset;

        // Chrome
        if (document.caretRangeFromPoint) {
            range = document.caretRangeFromPoint(clientPos.left, clientPos.top);
            textNode = range.startContainer;
            offset = range.startOffset;
        }

        // Only act on text nodes
        if (!textNode || textNode.nodeType !== Node.TEXT_NODE) {
            return "";
        }

        var data = textNode.textContent;
        //console.log("data = " + data);

        // Sometimes the offset can be at the 'length' of the data.
        // It might be a bug with this 'experimental' feature
        // Compensate for this below
        if (offset >= data.length) {
            offset = data.length - 1;
        }

        // Ignore the cursor on spaces - these aren't words
        if (isW(data[offset])) {
            return "";
        }

        // Scan behind the current character until whitespace is found, or beginning
        i = begin = end = offset;
        while (i > 0 && !isW(data[i - 1])) {
            i--;
        }
        begin = i;

        // Scan ahead of the current character until whitespace is found, or end
        i = offset;
        while (i < data.length - 1 && !isW(data[i + 1])) {
            i++;
        }
        end = i;

        // This is our temporary word
        var word = data.substring(begin, end + 1);

        // If at a node boundary, cross over and see what
        // the next word is and check if this should be added to our temp word
        if (end === data.length - 1 || begin === 0) {

            var nextNode = getNextNode(textNode);
            var prevNode = getPrevNode(textNode);

            // Get the next node text
            if (end == data.length - 1 && nextNode) {
                var nextText = nextNode.textContent;

                // Add the letters from the next text block until a whitespace, or end
                i = 0;
                while (i < nextText.length && !isW(nextText[i])) {
                    word += nextText[i++];
                }

            } else if (begin === 0 && prevNode) {
                // Get the previous node text
                var prevText = prevNode.textContent;

                // Add the letters from the next text block until a whitespace, or end
                i = prevText.length - 1;
                while (i >= 0 && !isW(prevText[i])) {
                    word = prevText[i--] + word;
                }
            }
        }
        return word;
    }

// Helper functions
// Whitespace checker
    function isW(s) {
        return /[\u0000-\u0026\u0028-\u0040\u005B-\u0060\u007B-\u00BF]/.test(s);
    }

// Barrier nodes are BR, DIV, P, PRE, TD, TR, ...
    function isBarrierNode(node) {
        return node ? /^(BR|DIV|P|PRE|TD|TR|TABLE)$/i.test(node.nodeName) : true;
    }

// Try to find the next adjacent node
    function getNextNode(node) {
        var n = null;
        // Does this node have a sibling?
        if (node.nextSibling) {
            n = node.nextSibling;

            // Does this node's container have a sibling?
        } else if (node.parentNode && node.parentNode.nextSibling) {
            n = node.parentNode.nextSibling;
        }
        return isBarrierNode(n) ? null : n;
    }

// Try to find the prev adjacent node
    function getPrevNode(node) {
        var n = null;

        // Does this node have a sibling?
        if (node.previousSibling) {
            n = node.previousSibling;

            // Doe this node's container have a sibling?
        } else if (node.parentNode && node.parentNode.previousSibling) {
            n = node.parentNode.previousSibling;
        }
        return isBarrierNode(n) ? null : n;
    }

// Show the popup with the selected text
    function showPopup(text, x, y) {
        // Create a new element for the popup
        var popup = document.createElement("div");
        popup.id = "hover-capture-popup";
        // Set the position of the popup
        popup.style.position = "fixed";
        popup.style.top = y + "px";
        popup.style.left = x + "px";
        // Add the selected text to the popup
        popup.innerHTML = text;
        // Append the popup to the document
        document.body.appendChild(popup);
    }

// Hide the popup
    function hidePopup() {
        // Remove the popup element from the document
        var popup = document.getElementById("hover-capture-popup");
        if (popup !== null) {
            popup.parentNode.removeChild(popup);
        }
    }

    const handleMouseMove = (event) => {
        var element = document.elementFromPoint(event.clientX, event.clientY);
        var position = { "left": event.clientX, "top": event.clientY };
        var word = getFullWord(position);
        if (word.trim() == "") return;
        const lastWord = lastWordRef.current
        if (word === lastWord) return;
        console.log("new word = [" + word + "]")
        console.log("word = " + word, "lastWord = ", lastWord)
        lastWordRef.current = word

        // var context = getContext(position); // Implement this function based on your requirements
        // if(context.length === 0 || word.length === 0) return;
        const processWordQueue = debounce(() => {
            //const wordToTranslate = wordQueue;
            sendCompletionRequest(station.localeIdentifier, word)
                .then(translatedWord => {
                    setTooltip({ show: true, content: translatedWord, position: { x: event.clientX, y: event.clientY -80 } });
                    //setTooltip({ show: true, content: translatedWord, position: { x: lastPositionRef.current.x, y: lastPositionRef.current.y - 80 } });
                });
        }, 100); // Adjust the debounce delay as needed
        processWordQueue();
        // Assuming translate function is defined
        // sendCompletionRequest("sv", word)
        // .then(translatedWord => {
        //     setTooltip({ show: true, content: translatedWord, position: { x: event.clientX, y: event.clientY -80 } });
        // });
        return;
        if (element && element.nodeType === Node.TEXT_NODE) {
            // Get the text that is currently hovered on
            var hoveredText = event.target.innerText.trim();
            // Get the text that is currently hovered on
            var text = element.nodeValue.trim();
            // Find the position of the word within the text
            var wordStart = text.lastIndexOf(" ", element.textContent.length - 1) + 1;
            var wordEnd = text.indexOf(" ", wordStart);
            if (wordEnd === -1) {
                wordEnd = text.length;
            }
            // Extract the word from the text
            var hoveredWord = text.substring(wordStart, wordEnd);

            // If text is hovered on, send a message to the background script with the hovered text
            if (hoveredText !== "") {
                console.log("sendMessage word: " + hoveredText);
                setTranslatedLatestMessage(word);
                //chrome.runtime.sendMessage({ action: "captureWord", word: hoveredText });
            }
        }
    };

    return (
        <div className="flex flex-col h-screen bg-white border-slate-100 dark:bg-slate-800 dark:border-slate-500 border-b p-4 pb-6 sm:p-10 sm:pb-8 lg:p-6 xl:p-10 xl:pb-8 space-y-6 sm:space-y-8 lg:space-y-6 xl:space-y-8">
            {/* Radio Info */}
            <div className="flex items-center space-x-8">
                <img src={"/images/" + station.image} alt="" width="88" height="88" className="flex-none rounded-lg bg-slate-100" loading="lazy" />
                <div className="min-w-0 flex-auto space-y-3 font-semibold">
                    <p className="text-cyan-500 dark:text-cyan-400 text-sm leading-6">
                        {station.title}
                    </p>
                    <button className="h-10 px-6 font-semibold rounded-md bg-black text-white" onClick={togglePlayPause}>
                        {isPlaying ? 'Pause Audio' : 'Play Audio'}
                    </button>
                    {/*<Button className="bg-black">Click me</Button>*/}
                    { station.translation && <ToggleSwitch checked={isTranslating} label="Live Translation" onChange={(newValue) => setIsTranslating(newValue)} // Directly use the new value
                    /> }
                </div>
            </div>

            {/* Audio Element */}
            <audio ref={audioRef} id="audioPlayer" hidden>
                <source src={station.streamURL} type="application/x-mpegURL" />
                Your browser does not support the audio tag.
            </audio>

            {/* Adjusted latestMessage and translatedLatestMessage containers */}
            <div className="flex-grow overflow-auto mt-4 text-lg leading-6 col-start-1 sm:col-span-2 lg:mt-6 lg:row-start-4 lg:col-span-1 dark:text-slate-400 text-left" style={{ maxHeight: '20vh', overflowY: 'auto' }}>
                {latestMessage}
            </div>
            {isTranslating &&
                <div className="flex-grow overflow-auto mt-4 text-lg leading-6 col-start-1 sm:col-span-2 lg:mt-6 lg:row-start-4 lg:col-span-1 dark:text-slate-400 text-left" style={{ maxHeight: '20vh', overflowY: 'auto' }}>
                    {translatedLatestMessage}
                </div>
            }
            {tooltip.show && (
                <div style={{ position: 'fixed', top: tooltip.position.y, left: tooltip.position.x, backgroundColor: 'lightgray', padding: '5px', borderRadius: '5px' }}>
                    {tooltip.content}
                </div>
            )}
        </div>
    );
};

async function sendCompletionRequest(source, prompt) { //}, model, apiKey) {
    const hostname = isDev() ? "t.125.io" : document.location.hostname;
    const url = `https://${hostname}/translate`
    const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            q: prompt,
            source: source,
            target: "en",
            format: "text",
            api_key: ""
        }),
        headers: { "Content-Type": "application/json" }
    });

    const r = await res.json()
    return r.translatedText
}

// Define the debounce function
const debounce = (func, delay) => {
    let inDebounce;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
};

export default StationPage;